<template>
  <div id="step2">
    <div class="step2-area">
      <!-- 登録ルート -->
      <div class="form-position">
        <p class="form-label">
          何を見てスポナビエージェントに<br class="br_onoff">
          登録しましたか？
        </p>

        <div class="uk-margin single-box">
          <select
            id="ref_media"
            v-model="formRef.ref_media.value"
            class="uk-select"
            name="ref_media"
          >
            <option value="">
              選択してください
            </option>
            <option
              v-for="r in refMediaRef"
              :key="r"
              :value="r"
            >
              {{ r }}
            </option>
          </select>
          <error
            v-if="formRef.ref_media.errorMessage"
            :message="formRef.ref_media.errorMessage"
          />
        </div>
      </div>

      <!-- 最も希望するサービス -->
      <div class="form-position">
        <p class="form-label">
          最も希望するサービスは何ですか？
        </p>

        <div class="uk-margin single-box">
          <select
            id="hope_service"
            v-model="formRef.hope_service.value"
            class="uk-select"
            name="hope_service"
          >
            <option value="">
              選択してください
            </option>
            <option
              v-for="h in hopeServiceRef"
              :key="h"
              :value="h"
            >
              {{ h }}
            </option>
          </select>
          <error
            v-if="formRef.hope_service.errorMessage"
            :message="formRef.hope_service.errorMessage"
          />
        </div>
      </div>

      <!-- 個人情報保護方針 -->
      <div class="form-position">
        <div class="form-label">
          個人情報保護方針
        </div>
        <div
          class="scroll-box uk-margin single-box"
          style="padding: 8px"
        >
          <privacy />
        </div>
      </div>

      <!-- 会員規約 -->
      <div class="form-position">
        <div class="form-label">
          会員規約
        </div>
        <div
          class="scroll-box uk-margin single-box"
          style="padding: 8px"
        >
          <membership />
        </div>
      </div>

      <div class="confirm-check-area">
        <div class="confirm-check-box">
          <input
            id="checkbox"
            v-model="formRef.checkbox.value"
            type="checkbox"
          >
          <label style="padding-left: 5px">
            会員規約および個人情報の取り扱いについて同意する
          </label>
        </div>
      </div>
    </div>

    <!-- 登録完了ボタン -->
    <div class="step2-btn-area">
      <button
        class="uk-button uk-button-default step2-btn-back"
        @click="onBack"
      >
        戻る
      </button>
      <button
        class="uk-button uk-button-primary step2-btn-complete"
        :disabled="!canRequest(formRef.checkbox.value)"
        @click="onClick"
      >
        会員登録を完了する
        <img
          :src="btnImage"
          alt="右矢印"
          style="margin-left: 3px; width: 25px"
        >
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Ref, defineComponent, SetupContext, toRefs, PropType } from 'vue'
import Error from './ErrorLabel.vue'
import { validate } from '../func/step2Form'
import privacy from './PrivacyPolicy.vue'
import membership from './MemberShip.vue'

type Props = {
  form: Ref
  refMedia: Array<string>
  hopeService: Array<string>
}

export default defineComponent({
  components: { Error, membership, privacy },
  props: {
    form: {
      type: Object as any,
      required: true,
    },
    refMedia: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    hopeService: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
  },
  emits: ['complete', 'back'],
  setup(props: Props, context: SetupContext): unknown {
    const { form, refMedia, hopeService } = toRefs(props)
    const btnImage = '/images/right-arrow-icon.png'

    // 会員登録ボタン押下可否
    let disableRegisterBtn = false

    // クリックイベントハンドラ
    const onClick = async (ev: any) => {
      disableRegisterBtn = true
      const res = await validate(props.form)
      if (!res) {
        ev.preventDefault()
        document.getElementById('signup-form').scrollIntoView()
        disableRegisterBtn = false
      } else {
        context.emit('complete')
      }
    }

    // 戻るボタンイベントハンドラ
    const onBack = () => {
      context.emit('back')
    }

    // 送信可否判定
    const canRequest = (check: boolean) => {
      return check && !disableRegisterBtn
    }

    return {
      onClick,
      onBack,
      formRef: form,
      refMediaRef: refMedia,
      hopeServiceRef: hopeService,
      btnImage,
      canRequest
    }
  },
})
</script>

<style scoped>
.step2-area {
  margin: 5rem auto;
  padding: 30px;
  background-color: #ececec;
  border-radius: 10px;
  max-width: 870px;
}

.scroll-box {
  height: 130px;
  border: 1px solid #ccc;
  background-color: white;
  overflow-y: scroll;
  text-align: left;
}

.form-position {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -25px;
}

.form-label {
  font-weight: 600;
  margin: 0 1rem 0 0;
  font-size: 14px;
}

.single-box {
  width: 25rem;
  font-size: 14px;
  border-color: #e5e5e5;
  border-radius: 10px;
}

.confirm-check-area {
  display: flex;
  align-items: center;
  justify-content: end;
}

.confirm-check-box {
  margin-right: 40px;
  font-size: 14px;
}

.step2-btn-area {
  display: flex;
  justify-content: center;
}

.step2-btn-complete {
  color: #fff !important;
  background-color: #e12b46;
  border-radius: 50px;
  width: 17rem;
  height: 3rem;
  font-size: 20px;
  font-size: 17px;
}

.step2-btn-back {
  border-radius: 50px;
  margin-right: 20px;
  background-color: #c3c0c0;
  color: white;
  width: 17rem;
  height: 3rem;
}

.uk-button-primary {
  margin-left: 10px !important;
}

.uk-select {
  height: 35px !important;
  border-radius: 5px !important;
  font-size: 14px;
}

@media (max-width: 950px) {
  .step2-area {
    margin: 2rem 3%;
    padding: 20px;
  }

  .form-position {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }

  .single-box {
    width: 100%;
  }

  .confirm-check-box {
    margin-right: 8px;
    font-size: 11px;
  }

  .confirm-check-area {
    align-items: flex-start;
    justify-content: center;
  }

  .step2-btn-area {
    flex-direction: column-reverse;
    align-items: center;
  }

  .step2-btn-complete {
    margin: 0 0 20px 0 !important;
  }

  .step2-btn-back {
    margin: 0 !important;
  }

  .uk-margin {
    margin-top: 5px !important;
  }
}
</style>
